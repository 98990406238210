import React from "react";
import ReviewCarousel from "./ReviewCarousel";
import ReadMore from "./ReadMore";
import state from "../core/State";
import * as colors from "../core/Color";

const Book = (data) => {
  const [, globalActions] = state();

  const sectionStyle = {
    backgroundColor: data.book.color,
  };

  const imgStyle = {
    height: "55vh",
  };

  const hrStyle = {
    paddingBottom: "0.2vh",
    border: "none",
    backgroundColor: data.book.textColor,
  };

  const headerStyle = {
    color: data.book.textColor,
  };

  const comingSoonStyle = {
    fontFamily: "Pinyon Script",
    color: colors.grey,
    fontSize: "3.5em",
  };

  return (
    <section id={data.index === 0 ? "Books" : ""} style={sectionStyle}>
      <div style={{ height: "8vh" }} />
      <div className={"container"} align="center">
        <h1 style={headerStyle}>{data.book.title}</h1>
        <hr style={hrStyle} />
        <div style={{ height: "4vh" }} />
        <div className={"row"}>
          <div className={"col-lg-4 col-md-12"}>
            <img src={data.book.url} alt={data.book.code} style={imgStyle} />
          </div>
          <div className={"col-lg-8 col-md-12"}>
            <div className={"d-none d-lg-block"}>
              <div style={{ height: "10vh" }} />
            </div>
            <div className={"d-lg-none"}>
              <div style={{ height: "5vh" }} />
            </div>
            {data.book.unreleased === undefined && (
              <ReviewCarousel
                name={data.book.code}
                reviews={data.book.reviews}
                color={data.book.reviewColor}
              />
            )}
            {data.book.unreleased && (
              <p style={comingSoonStyle}>
                {globalActions.decide(
                  "Coming soon in October!",
                  "Verschijnt in oktober!"
                )}
              </p>
            )}

            {data.book.unreleased && (
              <ReadMore
                code={data.book.code}
                description={globalActions.decide(
                  data.book.english_description,
                  data.book.dutch_description
                )}
                title={data.book.title}
                buttonTextColor={data.book.readMoreColor}
                buttonColor={data.book.readMoreButtonColor}
                closeButtonColor={data.book.closeButtonColor}
                closeColor={data.book.closeColor}
              />
            )}
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-12"}>
            <div style={{ height: "5vh" }} />
            {data.book.unreleased === undefined && (
              <ReadMore
                code={data.book.code}
                description={globalActions.decide(
                  data.book.english_description,
                  data.book.dutch_description
                )}
                title={data.book.title}
                buttonTextColor={data.book.readMoreColor}
                buttonColor={data.book.readMoreButtonColor}
                closeButtonColor={data.book.closeButtonColor}
                closeColor={data.book.closeColor}
              />
            )}
          </div>
        </div>
      </div>
      <div style={{ height: "8vh" }} />
    </section>
  );
};

export default Book;
