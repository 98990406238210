import React from "react";
import * as colors from "../core/Color";
import state from "../core/State";

const ReadMore = (data) => {
  const [, globalActions] = state();

  const readMoreStyle = {
    backgroundColor: data.buttonColor,
    color: data.buttonTextColor,
    width: "200px",
  };

  const closeStyle = {
    backgroundColor: data.closeButtonColor,
    color: data.closeColor,
  };

  return (
    <div>
      <button
        type="button"
        className="btn"
        data-toggle="modal"
        data-target={"#" + data.code + "-read-more"}
        style={readMoreStyle}
      >
        {globalActions.decide("Read More", "Lees meer")}
      </button>
      <div
        className="modal fade"
        id={data.code + "-read-more"}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {data.title}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" align="left">
              {data.description}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                style={closeStyle}
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadMore;
