import React from "react";
import Background from "../images/pauline.jpg";
import * as colors from "../core/Color";
import state from "../core/State";

const Biography = () => {
  const [, globalActions] = state();

  const sectionStyle = {
    backgroundColor: colors.white,
  };

  const imgStyle = {
    height: "55vh",
  };

  const hrStyle = {
    paddingBottom: "0.2vh",
    border: "none",
    backgroundColor: colors.grey,
  };

  const headerStyle = {
    color: colors.grey,
  };

  const dutchPart1 = `
    Ik hou van alles wat met boeken te maken heeft. Ik hou van hoe woorden verhalen worden
    en hoe woorden soms vleugels krijgen en je meevoeren naar plaatsen die je je nooit had
    kunnen indenken.
    Ik ben in Japan geboren, maar heb een deel van mijn vroege jeugd in Indonesie gewoond.
    Misschien zijn daardoor mijn herinneringen gevuld met geuren, kleuren en geluiden. Na
    mijn rechten studie in Leiden werkte ik voor het Ministerie van Buitenlandse Zaken en
    vertrok na een paar jaar naar het buitenland. Ik wist dat schrijven altijd een mogelijkheid
    was, waar ik ook zou zijn.
    `;

  const dutchPart2 = `
    In 2000 ben ik met de liefde van mijn leven en vier kinderen naar Johannesburg verhuisd;
    een fascinerende en inspirerende stad, die even zo moedeloos makend als hartverheffend is.
    Het zoeken naar ergens thuis te horen, ergens bij te passen, heeft mij beïnvloed in mijn
    schrijven. Ik heb geleerd dat alles mogelijk is, als je er maar in gelooft.
    Mijn boeken gaan over krachtige vrouwen in Afrika en in Holland, van wie de verhalen
    gebaseerd zijn op waar gebeurde historische omstandigheden. Vrouwen die hun
    lotsbestemming veranderen. Avontuurlijke vrouwen, sterk en uitdagend, tegen alle
    verwachtingen in. Om over hen te schrijven, heb ik uitgebreid onderzoek gedaan en ik
    vertrouw op mijn verbeeldingskracht om hun leven in te kleuren.
    Verhalen hebben altijd een belangrijk deel van mijn leven uitgemaakt en ik verheug me erop
    die met je te delen. Schrijf me, ik zou het leuk vinden je te leren kennen.
    `;

  const englishPart1 = `
    I love everything about books, but I love novels with a magical voice above everything else. A
    voice that comes from the soul. I love how words make stories and how sometimes the words
    take wings and carry you away to places you never imagined before.
    Born in Japan, I spent most of my early childhood in Indonesia. Maybe that’s why
    fragrances, colours and sounds inhabit my memories. My International Law study at Leiden
    University formed the basis for my early career. After working for the Ministry of Foreign
    Affairs for a couple of years, I moved abroad. I knew writing could always be an option,
    wherever I would be.
    `;

  const englishPart2 = `
    In 2000 I moved to Johannesburg with the love of my life and our four children; a
    fascinating and inspiring place to be. The search for belonging, for fitting in has influenced
    me in my writing. I have learned that everything is possible, as long as you believe in it.
    My books are about powerful women, in Africa and Holland, set in a historical time.
    Women who change the destiny of their paths. Adventurous women, strong and defiant,
    despite the odds against them. To write about them I have done extensive research and I
    trust my imagination to colour their stories.
    Stories have been a big part of my life and I am excited to share them with you.
    Write me any time, I’d love to get to know you.
    `;

  return (
    <section id="Biography" style={sectionStyle}>
      <div style={{ height: "8vh" }} />
      <div className={"container"} align="center">
        <h1 style={headerStyle}>
          {globalActions.decide("Biography", "Biografie")}
        </h1>
        <hr style={hrStyle} />
        <div style={{ height: "4vh" }} />
        <div className={"d-none d-lg-block"} align={"left"}>
          <div className={"row"} align={"left"}>
            <div className={"col-4"}>
              <img src={Background} alt="pauline" style={imgStyle} />
            </div>
            <div className={"col-4"}>
              <p style={{ color: colors.grey }}>
                {" "}
                {globalActions.decide(englishPart1, dutchPart1)}
              </p>
            </div>
            <div className={"col-4"}>
              <p style={{ color: colors.grey }}>
                {" "}
                {globalActions.decide(englishPart2, dutchPart2)}
              </p>
            </div>
          </div>
        </div>
        <div className={"d-lg-none"} align="center">
          <div className={"row"} align="center">
            <div className={"col-12"}>
              <img src={Background} alt="pauline" style={imgStyle} />
            </div>
            <div className={"offset-1 col-10"}>
              <br />
              <p style={{ color: colors.grey }}>
                {" "}
                {globalActions.decide(englishPart1, dutchPart1)}
              </p>
              <br />
            </div>
            <div className={"offset-1 col-10"}>
              <p style={{ color: colors.grey }}>
                {" "}
                {globalActions.decide(englishPart2, dutchPart2)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "4vh" }} />
    </section>
  );
};

export default Biography;
