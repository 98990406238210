import * as descriptions from "./Description";
import * as reviews from "./Review";
import * as colors from "../core/Color";

const data = [
  {
    title: "De achtste Zonnebloemen",
    code: "de_achtste_Zonnebloemen",
    english_description: descriptions.de_achtste_zonnebloemen_dutch_description,
    dutch_description: descriptions.de_achtste_zonnebloemen_dutch_description,
    url: require("../images/k1-compressed.jpg"),
    reviews: reviews.de_achtste_zonnebloemen_dutch_reviews,
    color: colors.white,
    textColor: colors.grey,
    readMoreColor: colors.white,
    readMoreButtonColor: colors.grey,
    closeButtonColor: colors.white,
    closeColor: colors.grey,
    reviewColor: colors.grey,
  },
  {
    title: "Onder de Vlinderboom",
    code: "onder_de_vlinderboom",
    english_description: descriptions.onder_de_vlinderboom_english_description,
    dutch_description: descriptions.onder_de_vlinderboom_dutch_description,
    url: require("../images/onder_de_vlinderboom.jpg"),
    reviews: reviews.onder_de_vlinderboom_reviews,
    color: colors.green,
    textColor: colors.white,
    readMoreColor: colors.green,
    readMoreButtonColor: colors.white,
    closeButtonColor: colors.green,
    closeColor: colors.white,
    reviewColor: colors.white,
  },
  {
    title: "Het Suikervogeltje",
    code: "het_suikervogeltje",
    english_description: descriptions.het_suikervogeltje_english_description,
    dutch_description: descriptions.het_suikervogeltje_dutch_description,
    url: require("../images/het_suikervogeltje.jpg"),
    reviews: reviews.het_suikervogeltje_reviews,
    color: colors.white,
    textColor: colors.grey,
    readMoreColor: colors.white,
    readMoreButtonColor: colors.grey,
    closeButtonColor: colors.white,
    closeColor: colors.grey,
    reviewColor: colors.grey,
  },
  {
    title: "Alexine",
    code: "alexine",
    english_description: descriptions.alexine_english_description,
    dutch_description: descriptions.alexine_dutch_description,
    url: require("../images/alexine.jpg"),
    reviews: reviews.alexine_reviews,
    color: colors.gold,
    textColor: colors.white,
    readMoreColor: colors.gold,
    readMoreButtonColor: colors.white,
    closeButtonColor: colors.gold,
    closeColor: colors.white,
    reviewColor: colors.white,
  },
];

export default data;
