import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { navbar } from "./core/Id";

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
$("body").scrollspy({ target: `#${navbar}` });
$(".carousel").carousel();
