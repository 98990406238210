import React from "react";
import { navbar } from "./core/Id";
import Books from "./components/Books";
import Contact from "./components/Contact";
import Biography from "./components/Biography";
import NavBar from "./components/Navbar";
import Header from "./components/Header";
import Videos from "./components/Videos";
import News from "./components/News";
import newsData from "./data/News";

function App() {
  return (
    <div
      className="container-fluid"
      data-spy="scroll"
      data-target={`#${navbar}`}
      style={{ paddingLeft: 0, paddingRight: 0 }}
    >
      {<NavBar />}
      {<Header />}
      <Books />
      <Biography />
      {<News news={newsData} />}
      {<Videos />}
      <Contact />
    </div>
  );
}

export default App;
