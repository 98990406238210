const alexine_reviews = [
  {
    review: "Alexine gelezen en van genoten! Lekker sfeervol en avontuurlijk.",
    reviewer: "Sandra Donkervoort",
  },
  {
    review:
      "Mijn zoon Sem (16) heeft in het afgelopen jaar uw boek over Alexine als speciaal onderwerp voor het vak Nederlands gelezen en behandeld, zijn enthousiasme heeft hem een 8.5 opgeleverd.",
    reviewer: "Richard Lucas",
  },
  {
    review:
      "Eenmaal begonnen aan het boek is er geen weg meer terug. De avonturen van deze jonge ontdekkingsreizigster blijven nog lang naspoken in je hoofd. Dit debuut vraagt om méér!",
    reviewer: "Marjolein Theunissen",
  },
  {
    review:
      "Een sprankelend verhaal over bijzondere uitdagingen en ontberingen, over liefde en verraad.",
    reviewer: "Limburg",
  },
  {
    review:
      "Pauline Vijverberg heeft de karakters van de twee vrouwen mooi uitgediept. Het is een zéér prettig leesbare historische roman die zeker aan te raden is. Het is te hopen dat er een tweede boek van deze schrijfster volgt.",
    reviewer: "Dettie Leestafelforum",
  },
  {
    review:
      "De auteur is zo goed in staat het avontuurlijke leven van Alexine Tinne te schetsen dat je het boek in één keer moet uitlezen... jammer dat het dan uit is! Van harte aanbevolen.",
    reviewer: "Aad Engelfriet",
  },
  {
    review:
      "De fascinatie voor de Nederlandse - tamelijk onbekende - ontdekkingsreiziger uit de 19e eeuw brengt ze wel over in het boek. Bovendien wordt een goed historisch beeld gegeven van zowel het aristocratische Nederland, als het onontgonnen gebied. Het boek belooft bovendien even spannend te zijn als de zoektocht van Alexine zelf.",
    reviewer: "Lilian Ahlers",
  },
  {
    review:
      "Een bijzonder debuut: een omvangrijke, zo op het oog met groot gemak geschreven historische roman waarvoor veel archiefonderzoek werd verricht door auteur Pauline Vijverberg. Heerlijk boek!",
    reviewer: "Annemarie Houwkink ten Cate-Schlichting",
  },
];

const het_suikervogeltje_reviews = [
  {
    review: "Gelezen! Op mijn rondreis door Afrika. Heel goed boek!!",
    reviewer: "Alice Grasveld (Suikervogeltje)",
  },
  {
    review:
      "Iedereen vond het leerzaam om meer over deze periode te lezen. Het boek is mooi geschreven en nodigde uit tot doorlezen. Al met al een boek om trots op te zijn naar onze bescheiden mening.",
    reviewer: "Sanne Sijbesma (leesgroep in Rotterdam)",
  },
  {
    review:
      "Een van de vele dingen die mij aanspraken was de (innerlijke) ontwikkeling die Ariaan doormaakte en dan daarnaast haar zusje Willemijn bij wie dat niet gelukt is. Misschien door de keuzes die ze maakten? Het mooie is dat Ariaan alles aanpakt waar ze ook terecht komt. Haar nieuwsgierigheid die haar in die planten heeft doen verdiepen en waar ze ook echt iets van gemaakt heeft en verder haar belangstelling voor de Afrikanen en hun verhalen. Je beschrijvingen van het landschap waardoor ik het echt voor me kon zien en dan ook de geschiedenis.",
    reviewer: "Anne-Louise Plugge",
  },
  {
    review:
      "Met plezier en interesse heb ik het boek gelezen. Het verveelde mij geen moment. Leest makkelijk, soms ook  spannend en zeer leuk hoe kleine details worden beschreven of terugkomen.",
    reviewer: "Piet Cornelissen",
  },
  {
    review:
      "Uw boek heeft mij gegrepen, emoties opgeroepen en het was jammer de laatste pagina te lezen. Een beter compliment kan ik u niet geven. Hartelijk dank voor uw creatie. Ik was er erg blij mee.",
    reviewer: "Martin Musch",
  },
  {
    review:
      "Gelukkig had ik nog voor vertrek uit Italie het boek 'Suikervogeltje' van Pauline Vijverberg op mijn Kindle gezet....wat een prachtig boek!! Ik denk dat iedere geëmigreerde moeder zich wel kan herkennen in dit verhaal. Ik waande me helemaal in de 17e eeuw... Een aanrader!! Kan niet wachten tot het volgende boek verschijnt een prachtig verhaal!",
    reviewer: "Betty van der Hulst",
  },
  {
    review: "Een heel mooi boek. ",
    reviewer: "Clasien Dijkhorst",
  },
  {
    review:
      "Geweldig mooi boek. Geeft een mooie inkijk hoe het leven zo’n 400 jaar geleden vooral voor vrouwen moet zijn geweest.",
    reviewer: "Willemijntje G. Hiemstra-Justa",
  },
  {
    review:
      "Met veel plezier heb ik je boek gelezen. Het is een prachtige historische roman. Je wordt als lezer meegenomen in de tijd met de personen in hun omgeving met landschap en planten. Ik heb bewondering voor de pioniers. Mooi de personage Ariaan als wijze, menselijke en volhardende zelfstandige vrouw.",
    reviewer: "Karin Mandersloot",
  },
  {
    review: "Ik heb “Het Suikervogeltje” in een adem uitgelezen en genoten! ",
    reviewer: "Heel mooi. Manon Broekhoff",
  },
  {
    review: "Heb enorm van je boek genoten!",
    reviewer: "Elisabeth Schoen-Hafez",
  },
  {
    review:
      "Congratulations on a great book! Nice ending with growth, acceptance and peace. ",
    reviewer: "Ronell Forbes",
  },
  {
    review:
      "Ik heb sinds lang niet meer zo genoten van een boek.Ik vond het boeiend ondergedompeld te worden in een paar eeuwen terug en kan me nu een hele goede voorstelling maken van het leven de ontberingen van onze voorouders.Wat een moed, kracht en doorzettingsvermogen was er nodig.Zo menselijk wat er allemaal gebeurde en gedacht werd.En er zitten wijze boodschappen tussen die ook nu toepasbaar zijn op relaties! Kortom, ik heb heerlijk gelezen, een mooi boek is altijd een goed cadeau voor de ziel.",
    reviewer: "Ariane van Notten",
  },
  {
    review:
      "Heb genoten van je boek! Heel mooi verhaal en een eye opener wat de VOC betreft. Staan echt leuke wetenswaardigheden in.",
    reviewer: "Michelle Wijkhuis",
  },
  {
    review:
      "Ik heb het boek net uitgelezen tijdens rondreis door Zuid Afrika. Ik vind het boek prachtig en heel goed geschreven.",
    reviewer: "Anneke Levi-ter Heide",
  },
  {
    review: "Las makkelijk en lekker weg.",
    reviewer: "Jo-Anna Wagenaar-Kornegoor",
  },
  {
    review:
      "Heb net het suikervogeltje in een ruk uitgelezen. Wat een mooi boek!!",
    reviewer: "Vera Bensmann",
  },
  {
    review:
      "Toen uw boek Het suikervogeltje bij ons binnenkwam was mijn zoon Sem (16) de eerste die het uitlas en me zei “pa die moet je lezen”. Heb het gelezen en ook hiervan genoten. Wat een bijzondere tijd waarin men toen leefde en wat een mooie manier waarop u dit hebt weergegeven in deze roman. Het feit dat het op echte personen is gebasseerd maakt het een boekwerk dat je graag in één adem uitleest.",
    reviewer: "Richard Lucas",
  },
  {
    review:
      "Mooi! Erg van genoten en geeft weer een beetje meer achtergrond in de geschiedenis.",
    reviewer: "Titia Tabak",
  },
  {
    review:
      "Onwijs van genoten! Lekker geschreven, en knappe combinatie van inzoomen op momenten afgewisseld met meer beschrijvende vertellingen die het mogelijk maakt om langere periodes te beschrijven. Complimenten.",
    reviewer: "Marieke Witjes",
  },
  {
    review:
      "Echt een aanrader hoor. Mooi historisch verhaal dat makkelijk wegleest.",
    reviewer: "Ria van Diemen",
  },
  {
    review:
      "Met gemak fladderde Het suikervogeltje om deze kalme lezer heen, maar daarna haast 'verslonden' door dochter Lieve, in twee dagen!",
    reviewer: "Jaap Metzlar",
  },
  {
    review:
      "Wat een prachtig boek was het. Ik heb het in een ruk uitgelezen. Heel mooi!",
    reviewer: "Jacqueline Kuipers",
  },
  {
    review:
      "Uit! Wat een prachtig boek. Leest fantastisch weg en is ook nog eens leerzaam.",
    reviewer: "Leontine van Eijck",
  },
  {
    review:
      "Wat een prachtig boek. Zo mooi geschreven...ik werd in ’t verhaal meegezogen...jammer dat het boek al uit is. Ik kijk al uit naar het volgende boek.",
    reviewer: "Ingrid Van’t Hof",
  },
  {
    review:
      "Sonder twyfel my mees sonderlinge Boek Van Die Dekade .... moeilik gewees on Trg Te keer Na 21 ste eeu Na al my avonture met my Geliefde Ariaan!",
    reviewer: "Christine Pretorius",
  },
  {
    review:
      "Wat een mooi verhaal, zo beeldend geschreven met prachtige metaforen. Mooi zoals al de zintuigen de lezer meenemen in het verhaal. Ik heb ervan genoten!",
    reviewer: "Cisca van Betten",
  },
  {
    review: "In een ruk uitgelezen. Ontroerend mooi.",
    reviewer: "Rieneke Tintel",
  },
  {
    review:
      "Vind de schrijfstijl erg prettig om te lezen, prachtig beschrijvend zonder te wollig te worden. Vond het super interessant om te lezen hoe Ariaan zich met kruiden bezig hield.",
    reviewer: "Iemke Janssen-de Leeuw",
  },
  {
    review: "Wat een goed historisch verhaal en mooi geschreven.",
    reviewer: "Tim Dröge",
  },
  {
    review:
      "Het was het eerste Nederlandse boek dat ik twee maanden geleden kocht na terug komen na paar jaar buitenland! Vond het geweldig. Het neemt je mee in een wereld dat onze geschiedenis is, maar ook in het leven van een vrouw in die tijd. Heel mooi hoe je de karakters neerzet!",
    reviewer: "Renate Groenendijk-Huisman",
  },
  {
    review:
      "Wat een prachtig boek. Een mooi inkijkje in de wereld in die tijd en heerlijke herinneringen aan Zuid Afrika.",
    reviewer: "Len Timmerman",
  },
  {
    review:
      "En toen was het uit. Jammer. Ik kon het op een gegeven moment niet meer wegleggen.",
    reviewer: "Pauline Maas",
  },
  {
    review:
      "Ik heb het boek in een adem uitgelezen. De sfeer in Afrika en in het weeshuis met de toen geldende normen zijn goed beschreven in het boek.",
    reviewer: "Karin Stibbe",
  },
  {
    review: "Vijverberg heeft haar research gedaan en dat voel je.",
    reviewer: "Amanda Morina Boekenkrant",
  },
  {
    review:
      "Het is een emotioneel verhaal dat je hart raakt en je anders doet laten kijken naar de geschiedenis van Kaap de Goede Hoop en de VOC. ",
    reviewer: "Anneke van Dijken Ann’s Boeken Blog",
  },
  {
    review: "Heerlijk boek! Heel boeiend. Goed geschreven.",
    reviewer: "Dignus Fransen van de Putte",
  },
  {
    review: "Prachtig verhaal, heerlijk boek.",
    reviewer: "Barbara Kok",
  },
  {
    review:
      "Het komt toch niet zo vaak voor dat ik op het laatst steeds langzamer ga lezen in een boek omdat het anders uit is. Prachtig verhaal en je reist helemaal mee in het verhaal van de twee dames.",
    reviewer: "Marguerite Fraser",
  },
  {
    review:
      "Een prachtige historische roman. Ik heb het in een ruk uitgelezen. Wat een prachtig verhaal! Boeiend, ontroerend en verslavend om te lezen. Kon het niet wegleggen en nu ik het uit heb, ben ik nog even aan het nagenieten. Een aanrader!",
    reviewer: "Marloes Reinink",
  },
  {
    review:
      "Ik zat helemaal in je boek. Heel ontroerend en knap geschreven. En ook erg interessant om over al die historische dingen te lezen! De reis op het schip, de leefwijze van de kolonisten, het verhaal van de slaven, de vroege kennis van kruiden enzovoort. Ik heb zo genoten van je mooie boek.",
    reviewer: "Leontine van de Stadt",
  },
  {
    review:
      "De eerste keer na jaren dat ik lees zonder dat elke verstoring mijn aandacht nodig heeft. Ik heb het boek al een paar dagen uit...en nog komen flitsen naar boven met stof om over na te denken. Had graag langer willen doorlezen!",
    reviewer: "Babette du Marchie Sarvaas",
  },
  {
    review: "Wat een prachtig verhaal. Ik heb genoten.",
    reviewer: "Carmen Tecklenburg",
  },
  {
    review:
      "Met heel veel plezier heb ik het Suikervogeltje gelezen. Ontzettend knap hoe je je hebt ingeleefd en zoveel research hebt gedaan. Het is een onderwerp waarvan ik, en waarschijnlijk velen anderen niets van wisten. Wat een leven voor die meisjes.",
    reviewer: "Henriette van Aerssen",
  },
  {
    review:
      "Wat een heerlijk boek. Zoveel onderzoek en zo lekker geschreven, het leest als een trein. Super leuk!",
    reviewer: "Bart van Zutphen",
  },
  {
    review:
      "Die nag in, tot net voor die geroep om gebed vanaf Fordsburg hier in Westdene, het ek Het Suikervogeljie gelees. Ek bevind my aan die einde van jou dromerige boek, Pauline Vijverberg, dat jy 'n stem gegee het aan meer as die 8 wesies vanaf Rotterdam, maar eerder aan die weeskinders van ons komplekse, lieflike, gekneusde land. ons almal wat hier woon is wees. Ik hebt in mij gebroken Nederlands gedromen daarna, dat die mogenlikheid sou bestaande, dat wij werklik vry kon wesen net hier waar wij ons bevinden.",
    reviewer: "Roela Hattingh",
  },
  {
    review:
      "Geweldig, lekker te lezen. Kon het moeilijk wegleggen. Zie nu al uit naar Het suikervogeltje, de film.",
    reviewer: "Margareth Plaisier",
  },
  {
    review:
      "Pauline heeft een prettige schrijfstijl waarbij ze de karakters goed uitwerkt zodat je met hen meeleeft. Hierdoor is een prachtig verhaal ontstaan met een stukje boeiende geschiedenis uit de 17e eeuw. Begin je aan ‘Het Suikervogeltje’ dan wil je dit boek het liefste in één ruk uitlezen. Als je van historische romans houdt, dan is dit boek absoluut een aanrader!",
    reviewer: "Berthi Smith",
  },
  {
    review:
      "De roman leest als een trein en de lezer krijgt gaandeweg een goed beeld van het koloniale leven in Afrika.",
    reviewer: "Lucas Zandberg Tzum",
  },
  {
    review:
      "Als lezer word je letterlijk vervoerd naar De Kaap, waar je je door Vijverbergs zeer beschrijvende stijl helemaal in waant, en waarbij zij de zintuigen niet ongeroerd laat. Mooie historische roman, gebaseerd op ware gebeurtenissen die zich afspelen rond personages die werkelijk hebben bestaan. Een aanrader voor een ieder die belangstelling heeft in de geschiedenis van Zuid Afrika ten tijde van de voc.",
    reviewer: "Joyce Bergvelt",
  },
  {
    review:
      "Gister begonnen met het lezen van “Het Suikervogeltje” prachtig geschreven door jou. Het is alsof “ik” meereis in het verhaal. Ik ga weer verder in dit boek en lees dit vandaag uit. Kan niet stoppen met lezen. Echt een aanrader. Een boek om in weg te dromen.",
    reviewer: "Martina Van der Kroft",
  },
  {
    review:
      'Veel succes met je prachtige boek dat nu actueel is. "Ik ben een mens door een ander mens" van Desmond Tutu. "Twee delen van dezelfde ziel." Met de huidige trend van compartimentering en indeling in hokjes is de zienswijze van Klaas een verademing.',
    reviewer: "Frans Wytema",
  },
  {
    review:
      "Dank je wel voor je boek. In een adem gisteren en vanochtend uitgelezen. Goed dat je de hardheid van het bestaan en het onrecht niet zachter hebt gemaakt.",
    reviewer: "Martine Gosselink",
  },
  {
    review:
      "Pauline Vijverberg schetst al met al een goed beeld van hoe moeilijk het leven in en rond Kaapstad in die tijd was. Dit boek is een echte aanrader voor iedereen die van historische romans houdt.",
    reviewer: "Erik van de Ven",
  },
  {
    review:
      "Wat een prachtig boek! Ik heb het Suikervogeltje geboeid gelezen en er komt zoveel in samen. De geschiedenis van Zuid Afrika, de verwachtingen van de weesmeisjes, de opbloeiende liefdes, de teleurstellingen. Je woordkeuze is prachtig en subtiel. Je boek is een kunstwerk waar ik enorm van heb genoten.",
    reviewer: "Sylvia Siertsema",
  },
  {
    review:
      "Ik heb je boek bijna uit: heerlijk geschreven, zo soepel, en beeldend, je hebt het helemaal.",
    reviewer: "Alexandrine Bijl",
  },
  {
    review:
      "Ik verzaak mijn plichten. Ben gevangen in het suikervogeltje. Wat een heerlijk boek.",
    reviewer: "Carine Kappeyne ",
  },
  {
    review:
      "Het was een groot plezier je boek te lezen; in sommige delen werd ik helemaal meegezogen. Bij de episode van Azinza en Klaas moest ik denken aan de beklemmende sfeer van Coetzee's Disgrace.",
    reviewer: "Auteur Peter Dicker ",
  },
  {
    review:
      "Dit is een mooie ontroerende roman van een schrijfster die veel van het achttiende- eeuwse Zuid-Afrika weet.",
    reviewer: "Dr Hans Ester Reformatorisch Dagblad",
  },
  {
    review:
      "Geslaagde faction. Een mooie historische roman die je met plezier leest en er nog wat van opsteekt ook.",
    reviewer: "Adrianus Koster LeesKost",
  },
  {
    review:
      "Ik vond het een heel mooi boek, interessant en mooi beschreven. Erg mooi, met veel liefde voor Afrika geschreven en beschreven, geschiedkundig ook erg interessant, en vooral de beschrijving en uitleg van Ariaans talent voor en kennis van de planten en kruiden vond ik heel mooi! Ik kijk alweer uit naar het volgende boek!",
    reviewer: "Marianne van Selow",
  },
  {
    review:
      "Een indrukwekkende geschiedenis van deze meisjes/vrouwen, beeldend neergezet. Je gevoel voor sfeer is een sterk punt. Ook de studie die je hebt gedaan voor dit boek is voor mij leerzaam en geeft precies weer wat er in die tijd speelde. Iedere avond heb ik weer zin om verder te lezen.",
    reviewer: "Annelies Frima",
  },
  {
    review: "Uit! Mooi. Stil…",
    reviewer: "Mary Broekhuijsen",
  },
  {
    review:
      "Heel mooi verhaal. Ik heb van begin tot eind genoten van het boek en meegeleefd met Ariaan! Nu op naar boek 3!",
    reviewer: "Gilda van Liempt",
  },
  {
    review:
      "Wat een goed boek. Ik heb ervan genoten en ik heb het achter elkaar zitten lezen. De spanning bleef er goed in. En het geeft goed allerlei nuances weer, in het leven van toen, de verschillende groepen, belangen, de gouverneur en zijn gevolg en eigen interesses. Ook heel mooi hoe de gevoelens zijn weergegeven van een jonge nuchtere vrouw, Ariaan, die toch ook gevoelig is voor de verhalen van Klaas, de indrukken van de natuur enz. Echt erg leuk om te lezen en die plekken in de Kaap voor te stellen.",
    reviewer: "Marina Manger Cats",
  },
  {
    review:
      "Wat heb je mooi geschreven. Heb er echt van genoten. Kijk al uit naar je volgende boek!",
    reviewer: "Lydie Vellema",
  },
  {
    review:
      "Pauline Vijverberg is een meester in het beschrijven van de levens van mensen en hun gevoelens, en dat allemaal in een tijd waarvan wij ons eigenlijk geen voorstelling meer kunnen maken! Heel knap! Jammer toen het boek uit was, want hoe zou het verder zijn gegaan met de verschillende families??",
    reviewer: "Aad Engelfriet",
  },
  {
    review:
      "Pauline Vijverberg laat geschiedenis herleven. Het suikervogeltje is een pakkend verhaal over Rotterdamse weesmeisjes die naar de Kaap worden gestuurd. Het leest lekker weg. ",
    reviewer: "Jaap du Marchie Sarvaas",
  },
  {
    review:
      "Ik heb het suikervogeltje in een adem uitgelezen. Wat een ongelofelijk verhaal. Twee zusjes die samen naar Zuid Afrika emigreren en hun levens te volgen. Ik kon mij in iedereen verplaatsen. Dat heb je heel goed gedaan.",
    reviewer: "Ilonka Jankovich",
  },
  {
    review: "Genoten van het boek. Heerlijk geschreven.",
    reviewer: "Petra van Boetzelaar",
  },
  {
    review:
      "Heb net het boek uitgelezen! Met plezier. Heel gevarieerd: het leven van de weesmeisjes, de reis op zee naar de Kaap, het bestaan van de eerste immigranten. Het leest vlot, het is interessant en er is voldoende spanning.",
    reviewer: "Trix Ambags",
  },
];

const onder_de_vlinderboom_reviews = [
  {
    review:
      "Wat een prachtige boek (weer)! De vervlechting van het persoonlijke verhaal van de Boerenoorlog met de huidige wetenschap van epigenetica maakt het boek voor iedereen die het leest ook nog eens persoonlijk. Want wat is jouw familiegeschiedenis? En welke invloed heeft die op jou? Mij raakte vooral die liefde voor Zuid-Afrika, die wellicht dus ook doorgegeven kan worden.",
    reviewer: "Iemke Janssen-de Leeuw",
  },
  {
    review:
      "Ik heb net het boek in één ruk uitgelezen. Ben erg geroerd. Wat een mooi boek weer.",
    reviewer: "Carmen Tecklenburg",
  },
  {
    review:
      "Onder de vlinderboom verdient een literaire prijs.Een echte page turner. Paar dagen geleden gekocht en kan niet stoppen met lezen.",
    reviewer: "Chris de Valck",
  },
  {
    review:
      "In een adem heb ik jouw prachtig geschreven boek uitgelezen. Het voerde mij mee naar een land en een periode waar ik eigenlijk niets of nauwelijks iets van wist. Wat heb jij een bijzondere gave om te schrijven... Iedere keer weer!",
    reviewer: "Ellen Dissevelt",
  },
  {
    review:
      "Het is boeiend, mooi beeldend geschreven, ook poëtisch.  Het is een rijk boek. Ik hoop, dat veel mensen van je boek mogen genieten. Rijk aan geschiedenis, cultuur. Bovendien verwerk je, als rode draad, epignetica, op volhardende manier. Voor mij een eyeopener. Ondanks de vreselijke feiten die onmiddellijk al aan de orde zijn, is het geen somber boek. Het blijft gaan over tolerantie, over de menselijke waardigheid en daarom over het goede tussen mensen.",
    reviewer: "Toos Zijlstra",
  },
  {
    review:
      "Het verhaal is prachtig en meeslepend, zó origineel en interessant. Natuurlijk heb ik weer heel veel opgestoken, wat zo heerlijk is, maar het meest ben ik onder de indruk van de mensen in dit boek. Ze gaan zo heel lief met elkaar om en ze zijn ontwapenend. Ook groeien ze door het leven zelf en dat is ook zo mooi te lezen. Kortom, het is een superboek.",
    reviewer: "Anne-Louise Plugge",
  },
  {
    review:
      "Onder de Vlinderboom: De diepte van het menselijk leed zo onverbloemd en helder geschreven. In stilte beleef ik de werkelijkheid van het verre verleden waarin tragiek en zelfredzaamheid worden belicht. In het bijzonder de moederliefde samengaand met opofferingen ter bescherming van haar kinderen. Kortom.....een aanrader.",
    reviewer: "Lambert Duys",
  },
  {
    review:
      "Ik vind haar een knappe verhalenverteller. Ik werd echt weer meegenomen in het verhaal.",
    reviewer: "Sanne Bierens",
  },
  {
    review: "Een knap gecomponeerde roman met verrassende wendingen.",
    reviewer: "Stella de Rijcke (Visie)",
  },
  {
    review:
      "Een knap geconstrueerd verhaal. Ik heb genoten. Het boek is moeilijk weg te leggen, de korte afwisselende hoofdstukken zijn boeiend en nemen je mee in de wereld van de twee vrouwen.",
    reviewer: "Marjet Maks (auteur)",
  },
  {
    review:
      "Boek uitgelezen. Wat goed vond ik het!!! Heb ‘t echt met spanning uitgelezen. En het geeft ook veel te denken. Ik hoop dat het nog eens in het Engels vertaald kan worden.",
    reviewer: "Marina Manger Cats",
  },
  {
    review:
      "Ze weet mijn snaren te raken. Je zit als lezer in het verhaal, bent de mensen, wordt op ideeën gebracht en wil de woorden alsmaar herlezen. Het boek kreeg daarom op vele bladzijden oortjes.",
    reviewer: "Valesca de Voogd",
  },
  {
    review:
      "I have been savouring every word. I loved the magic between loved once perfectly described with the sentence ‘er moest een rimpeling in de lucht zijn geweest.’ Never before did I read a book that made me re-think who I am in regards to the history of my forebears. Onder de Vlinderboom has caused the rimpeling in my life that you wrote about.  I think I should start all over again when I am finished. Poetry and Science: sublime!",
    reviewer: "Christine Pretorius",
  },
  {
    review: "Wat een prachtig geschreven verhaal.",
    reviewer: "Barbara Kok",
  },
  {
    review:
      "Wat een mooi boek weer. Ik kon het op het laatst niet wegleggen. Het heeft mij ook wel aan het denken gezet. Jammer dat het uit is.",
    reviewer: "Pauline Maas",
  },
  {
    review:
      "Heb het boek gelezen en vond het mooi en bij vlagen heel ontroerend.",
    reviewer: "Patrice van der Veen",
  },
  {
    review:
      "Ik vind het erg knap dat ik in jouw verhalen zo dicht bij de hoofdpersonen kom, heel indringend en ze blijven daardoor heel lang bij me. Verdient een groot publiek.",
    reviewer: "Leontine van de Stadt",
  },
  {
    review: "Een heerlijk boek!",
    reviewer: "Willemijn van Strien",
  },
  {
    review: "Een uitermate boeiend en goed geschreven historische roman.",
    reviewer: "Ad Koster (Leeskost)",
  },
  {
    review:
      "Met de Vlinderboom kon ik niet ophouden. Ik vond de twee tijdslijnen die zo door elkaar gevlochten zijn en uiteindelijk samenkomen heel spannend.",
    reviewer: "Henriette van Aerssen",
  },
  {
    review:
      "Diep onder de indruk, heb titel op mijn fb gezet. Dank voor dit ontroerende verdrietige boek.",
    reviewer: "Groet Carly Mak - van den Boezem",
  },
  {
    review:
      "Een boek waar ik echt van genoten heb, mooie dooreenvlechting van 2 verhalen, erg zintuiglijke roman, boordevol natuur geuren en kleuren... kortom feest van lezen en verdiepen!! Ook een moderne roman door het Amsterdamse erbij te halen...veel aantekeningen gemaakt!",
    reviewer: "Hilly van Hassent",
  },
  {
    review:
      "Vandaag in een ruk Onder de Vlinderboom uitgelezen –ik kon niet stoppen. Prachtig, prachtig! Erg genoten!",
    reviewer: "Marloes Reinink",
  },
  {
    review:
      "Heb de halve nacht doorgelezen, was in de ban van het verhaal. Heel mooi beschreven.",
    reviewer: "Frederike Hollander ",
  },
  {
    review:
      "Ik heb je boek in drie dagen uitgelezen. Dank voor het werk aan je boeken.",
    reviewer: "Ad Vijverberg",
  },
  {
    review:
      "De karakterisering van de hedendaagse hoofdpersonen is heel goed en het hele boek getuigt van een zeer erudiet/belezen schrijfster. De beschrijvingen van de natuur en metaforen in het verhaal zijn erg mooi. Prachtige scenes beschreven.",
    reviewer: "Carine du Marchie Sarvaas",
  },
  {
    review: "Onder de Vlinderboom uitgelezen. Wat een prachtig boek weer.",
    reviewer: "Dominique Pirard",
  },
  {
    review:
      "Wat een kunst om de toch trieste geschiedenis met zoveel passie en emotie te schrijven. De snaren van mijn hart werden meermalen zeer geraakt. Dank je wel voor dit prachtige boek.",
    reviewer: "Martin Musch",
  },
  {
    review:
      "Wat een prachtige, historische en liefdevolle roman. Onder de vlinderboom eindigt waar het begon. De cirkel is rond met eerbetoon aan een familie met gevolgen van oorlog, concentratiekamp en diaspora. De jonge hoofdpersoon volgt het spoor terug dat leidt naar kennis, begrip en rust. De verbondenheid met de familie is zo liefdevol voelbaar. Klasse.",
    reviewer: "Karin Mandersloot",
  },
  {
    review: "Wat een indrukwekkend verhaal en heel mooi geschreven.",
    reviewer: "Bernadette Veeger",
  },
  {
    review:
      "De feiten en citaten versterken het verhaal en leren je en passant nog wat over de geschiedenis en door de twee tijdlijnen blijft de vaart erin.",
    reviewer: "D.E. Heerens (NBD Biblion)",
  },
  {
    review:
      "Onder de vlinderboom weet de platgetreden paden te vermijden door een tweede vertellijn te activeren. Het talent van Vijverberg ligt in haar vermogen een vervlochten verhaal te vertellen.",
    reviewer: "Dr. Hans Ester",
  },
  {
    review:
      "Wie Onder de vlinderboom leest wordt getroffen door het feilloze inlevingsvermogen van Pauline Vijverberg. Ze werkt met grote taalvirtuositeit twee parallelle verhaallijnen uit. Op het einde van het boek is de ervaring van serendipiteit voor de lezer compleet.",
    reviewer: "Herman Meulemans (LitNet)",
  },
  {
    review:
      "Het was een bijzonder verhaal en zo knap hoe ook interessante informatie erin is verwerkt. Boeiend van begin tot eind! Epigenetica zet me ook aan het denken.",
    reviewer: "Plony Zwetsloot",
  },
  {
    review: "Boek in 3 dagen uitgelezen....prachtig!",
    reviewer: "Christa Beydals-Degen",
  },
  {
    review:
      "In één ruk uit. Interessante geschiedenis mooi inleefbaar geschreven. Historische feiten verweven met gedetailleerde beschrijvingen. Epigenetica is zeer interessant.",
    reviewer: "Annelies Frima",
  },
  {
    review:
      "Prachtig om te lezen. Zo heerlijk van de ene belevingswereld in de andere te stappen met elk hoofdstuk.",
    reviewer: "Lydie Vellema",
  },
  {
    review: "Mooi en boeiend.",
    reviewer: "Tania Dubois",
  },
  {
    review: "Wat een indrukwekkend en intrigerend verhaal!",
    reviewer: "Peter Dicker",
  },
  {
    review:
      "Heel mooi en gelaagd geschreven. Al lezend bekroop me het gevoel van ‘kijken in een doorzichtige spiegel’.",
    reviewer: "Athar Husain Khan",
  },
  {
    review: "Veel herkenbaar.",
    reviewer: "Anneke Stal",
  },
  {
    review: "Het is een heel mooi boek.",
    reviewer: "Coby Hanekamp",
  },
  {
    review:
      "Goed geschreven en een boeiend onderwerp vooral voor Nederlandse Zuid-Afrikanen.",
    reviewer: "Wilma Schavemaker",
  },
];

const de_achtste_zonnebloemen_dutch_reviews = [
  {
    review:
      "Iemand meeslepen doe je zo; met woorden die je ruikt, voelt en hoort. En ik zie een Zuid-Afrika die toen al zoekende was naar haar eigen identiteit.",
    reviewer: "Lydie Vellema",
  },
  {
    review: "Knap gedaan en van A tot Z geboeid gelezen!",
    reviewer: "Gert-Jan Schaap (Visie)",
  },
  {
    review:
      "Een mooi historisch kunststukje van feit en fictie dat het lezen meer dan waard is.",
    reviewer: "Dettie Leestafel",
  },
  {
    review:
      "Een fraai geschreven en verrukkelijk boek. Poëtisch op zijn tijd, invoelend, soms huiveringwekkend, zó beeldend geschreven dat je de geur van het land ruikt, de vogels, insecten en bloemen ziet, de kleuren voor je ogen dansen. Knap gecomponeerd.",
    reviewer: "Els Leeskost",
  },
  {
    review:
      "De auteur maakt het universeel thema van migratie, het achterlaten van het vertrouwde, het opzoeken van nieuwe horizonten en vooral het aangrijpen van nieuwe kansen in donkere tijden, op briljante wijze aanschouwelijk. Het mysterieuze kronkelpad van van Goghs Zonnebloemen-schilderij smeedt het gebroken gevoelsleven van nieuwkomers samen tot een kleurrijk, tijdloos fresco. Dat het zo spannend wordt beschreven, maakt de roman onvergetelijk.",
    reviewer: "Herman Meulemans",
  },
  {
    review:
      "Vanaf de eerste woorden trekt Pauline Vijverberg je een diep menselijk verhaal binnen waarin liefde, gemis, weemoed, kunst en oorlogsgeweld belangrijke thema’s zijn.",
    reviewer: "Joop Liefaard",
  },
  {
    review:
      "Hoewel je volop heen en weer slingert door de tijd, als zonnebloemen in de wind, is het een samenhangend geheel. Heel fraai einde ook. Ik heb genoten.",
    reviewer: "Lucas Zandberg",
  },
  {
    review: "Prachtig!",
    reviewer: "Gilda van Liempt",
  },
  {
    review:
      "Mooie kennis verweven in het verhaal, de kleurenleer van Goethe, de geschiedenis...Vol van het boek.",
    reviewer: "Hilly van Hassent",
  },
  {
    review:
      "Net als in haar eerder boeken verweeft Vijverberg ook nu op een knappe manier feit en fictie. Het fictieve sausje dat Vijverberg aan de feiten toevoegt is de prachtige lofzang op kunst en creativiteit.",
    reviewer: "Nicole van der Elst (Hebban)",
  },
  {
    review:
      "Een spannende combinatie van kunst-, migratie- en familiegeschiedenis. Aangenaam leesbaar.",
    reviewer: "Nelleke Manneke (NBD Biblion)",
  },
  {
    review:
      "Pauline Vijverberg schrijft om te lezen. Mooiste vind ik  dat kleuren geuren en geluiden kleuren zijn. Een aanrader.",
    reviewer: "Jaap Sarvaas",
  },
  {
    review: "In één ruk uitgelezen. Prachtig.",
    reviewer: "Pauline Maas."
  },
  {
    review: "Mooi gedaan dat je heen en weer gaat in de tijd met het boek. Met zoveel passie en zoveel detail geschreven.",
    reviewer: "Ingrid van ‘t Hof"
  },
  {
    review: "Zulke mooie poëtische beschrijvingen. Zat meteen in het verhaal.",
    reviewer: "Marianne van Selow"
  },
  {
    review: "Genoten van alle geuren, kleuren en sfeerschetsen.",
    reviewer: "Cisca Jonkman"
  },
  {
    review: "Bijzonder en interessant verhaal. Ik heb ervan genoten.",
    reviewer: "Bernadette Veeger"
  },
  {
    review: "Een prachtig boek.",
    reviewer: "Sylvia Siertsema"
  },
  {
    review: "Mooi geschreven. De combinatie geschiedenis en fictie is echt subliem.",
    reviewer: "Ankimon Vernede"
  },
  {
    review: "Hoe geraak ik van mijn stoel? Ik moet douchen en werken maar ’t is zo spannend! Wil alleen maar verder lezen. Het is prachtig.",
    reviewer: "Ans Cauwels"
  },
  {
    review: "Wat een fijn boek om even helemaal in een andere wereld te zijn, ik heb ervan genoten.",
    reviewer: "Josine Overdevest"
  },
  {
    review: "Met veel plezier gelezen en, zoals eerder ook, weer wat wijsheid opgedaan.",
    reviewer: "Jantine Zoethout"
  },
  {
    review: "In een ruk uitgelezen. Prachtig zoals het zintuiglijk waarneembaar wordt.",
    reviewer: "Helly van der Wind"
  },
  {
    review: "Een mooi verhaal. Met veel plezier gelezen.",
    reviewer: "Truus Versluis"
  },
  {
    review: "Prachtig boek. Interessant onderwerp.",
    reviewer: "Tania Dubois-Krawczyk"
  },
];

export {
  alexine_reviews,
  het_suikervogeltje_reviews,
  onder_de_vlinderboom_reviews,
  de_achtste_zonnebloemen_dutch_reviews,
};
