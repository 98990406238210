import React from "react";
import state from "../core/State";
import { navbar } from "../core/Id";
import Biography from "./Biography";

const NavBar = () => {
  const [, globalActions] = state();
  return (
    <nav
      id={`${navbar}`}
      className="navbar fixed-top navbar-expand-md navbar-light bg-light"
    >
      <a className="navbar-brand" href="#Home">
        Pauline Vijverberg
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#collapsingNavbar"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="navbar-collapse collapse w-100" id="collapsingNavbar">
        <ul className="navbar-nav w-100 justify-content-left">
          <li className="nav-item">
            <a className="nav-link" href="#Home">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#Books">
              {globalActions.decide("Books", "Boeken")}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#Biography">
              {globalActions.decide("Biography", "Biografie")}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#News">
              {globalActions.decide("News", "Actueel")}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#Videos">
              {globalActions.decide("Videos", "Video’s")}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#Contact">
              Contact
            </a>
          </li>
        </ul>

        <ul className="nav navbar-nav ml-auto w-100 justify-content-end">
          <li className="nav-item">
            <a
              className="nav-link"
              href="#Home"
              onClick={() => globalActions.switch()}
            >
              {globalActions.getOpposite()}
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
