import React from "react";
import data from "../data/Book";
import Book from "./Book";

const Books = () => {
  return (
    <div>
      {Array.from(data).map((book, i) => (
        <Book key={i} index={i} book={book} />
      ))}
    </div>
  );
};

export default Books;
