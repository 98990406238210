import React from "react";
import * as colors from "../core/Color";

const reviewBlock = {
  paddingLeft: "20%",
  width: "80%",
};

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const ReviewCarousel = (data) => {
  const reviewerStyle = {
    fontFamily: "Pinyon Script",
    color: data.color,
    fontSize: "2em",
  };

  const reviewStyle = {
    color: data.color,
  };

  return (
    <div
      id={data.name + "-reviews"}
      className="carousel slide"
      data-ride="carousel"
    >
      <div className="carousel-inner" align="center">
        {shuffle(Array.from(data.reviews)).map((review, i) => {
          const active = i === 0 ? " active" : "";
          return (
            <div
              key={i}
              className={"carousel-item" + active}
              style={reviewBlock}
            >
              <p style={reviewStyle}>{review.review}</p>
              <p style={reviewerStyle}>{review.reviewer}</p>
            </div>
          );
        })}
      </div>
      <a
        className="carousel-control-prev"
        href={"#" + data.name + "-reviews"}
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href={"#" + data.name + "-reviews"}
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
};

export default ReviewCarousel;
