const defaultDescription = "Click here to read more";

const data = [
  {
    description: defaultDescription,
    image: require("../images/news/2020-dec-1.png"),
    url:
      "https://mijnboekenblog.com/mijn-mening/pauline-vijverberg-de-achtste-zonnebloemen/",
  },
  {
    description: defaultDescription,
    image: require("../images/news/2020-dec-2.png"),
    url: "https://www.leeskost.nl/2020/10/de-achtste-zonnebloemen/",
  },
  {
    description: defaultDescription,
    image: require("../images/news/2020-dec-3.png"),
    url: "https://perfecteburen.nl/reviews/authors/pauline-vijverberg/",
  },
  {
    image: require("../images/news/2020-nov-1.jpeg"),
  },
  {
    image: require("../images/news/2020-nov-2.jpeg"),
  },
  {
    description: defaultDescription,
    image: require("../images/news/boekenweek-onder-de-vlinderboom-min.png"),
    url:
      "https://www.boekenweek.nl/activiteit/ontmoet-de-schrijver-pauline-vijverberg-over-haar-nieuwe-boek-onder-de-vlinderboom/",
  },
  {
    description: defaultDescription,
    image: require("../images/news/booksandwords-min.png"),
    url: "http://www.booksandwords.be/op-zoek-naar-nieuwe-boeken-deel-2/",
  },
  {
    description: defaultDescription,
    image: require("../images/news/freubel-gonda-min.png"),
    url:
      "http://freubel-gonda.blogspot.com/2019/05/naar-de-luie-tuinman-interessant-lezen.html",
  },
  {
    description: defaultDescription,
    image: require("../images/news/historiek-min.png"),
    url: "https://historiek.net/het-verhaal-van-de-herinnering/76304/",
  },
  {
    description: defaultDescription,
    image: require("../images/news/ireneinhetatelier-min.png"),
    url:
      "https://ireneinhetatelier.blogspot.com/2018/07/lezen-in-de-warmte-boekentips.html",
  },
  {
    description: defaultDescription,
    image: require("../images/news/kranenburgh-min.png"),
    url:
      "https://www.kranenburgh.nl/activiteit/197/schrijversloopje-met-pauline-vijverberg",
  },
  {
    description: defaultDescription,
    image: require("../images/news/leeskost-onder-de-vlinderboom-min.png"),
    url: "https://www.leeskost.nl/2018/07/onder-de-vlinderboom/",
  },
  {
    description: defaultDescription,
    image: require("../images/news/litnet-min.png"),
    url:
      "https://www.litnet.co.za/onder-de-vlinderboom-blijven-de-boerenoorlog-en-de-hongerwinter-nog-altijd-nazinderen/",
  },
  {
    description: defaultDescription,
    image: require("../images/news/steemit-min.png"),
    url:
      "https://steemit.com/boeken/@eagleye/pauline-vijverberg-onder-de-vlinderboom",
  },
  {
    description: defaultDescription,
    image: require("../images/news/surfingann-min.png"),
    url:
      "https://surfingann.blogspot.com/2018/03/onder-de-vlinderboom-pauline-vijverberg.html",
  },
  {
    description: defaultDescription,
    image: require("../images/news/gooieneemlander-min.png"),
    url:
      "https://m.gooieneemlander.nl/cnt/dmf20180926_94491008/de-zoektocht-van-pauline-vijverberg?utm_source=google&utm_medium=organic",
  },
  {
    description: defaultDescription,
    image: require("../images/news/ad-min.png"),
    url:
      "https://www.ad.nl/rotterdam/rotterdams-weeshuis-leverde-bruiden-voor-kolonisten-in-zuid-afrika~a0970ec3/?referrer=https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=12&ved=2ahUKEwiDm5LZzeblAhWdShUIHZ2wA-Q4ChAWMAF6BAgEEAE&url=https%3A%2F%2Fwww.ad.nl%2Frotterdam%2Frotterdams-weeshuis-leverde-bruiden-voor-kolonisten-in-zuid-afrika~a0970ec3%2F&usg=AOvVaw1KKOH3cFRnq4fhwMqSjc4J",
  },
  {
    description: defaultDescription,
    image: require("../images/news/lirian-min.png"),
    url: "https://www.lirian.nl/lirianleest/het-suikervogeltje",
  },
  {
    description: "De zoektocht van Pauline Vijverberg",
    image: require("../images/news/textile-collection-min.png"),
    url:
      "http://berthi.textile-collection.nl/2018/03/08/pauline-vijverberg-in-het-zuid-afrikahuis/",
  },
  {
    description: defaultDescription,
    image: require("../images/news/leeskost-min.png"),
    url: "https://www.leeskost.nl/2017/03/bruiden-voor-kaapstad/",
  },
  {
    description: defaultDescription,
    image: require("../images/news/rijnmond-min.png"),
    url:
      "https://www.rijnmond.nl/nieuws/151937/Rotterdams-weeshuis-leverde-bruiden-voor-kolonisten",
  },
  {
    description: defaultDescription,
    image: require("../images/news/boekenkrant-min.png"),
    url: "http://www.boekenkrant.com/boer-zoekt-vrouw/",
  },
  {
    description: defaultDescription,
    image: require("../images/news/surfingann-onder-de-vlinderboom-min.png"),
    url:
      "https://surfingann.blogspot.com/2017/04/het-suikervogeltje-pauline-vijverberg.html",
  },
  {
    description: defaultDescription,
    image: require("../images/news/berthi.textile-collection-min.png"),
    url:
      "http://berthi.textile-collection.nl/2017/03/12/het-suikervogeltje-pauline-vijverberg/",
  },
  {
    description: "Recensie: Pauline Vijverberg – Het suikervogeltje",
    image: require("../images/news/tzum-min.png"),
    url:
      "https://www.tzum.info/2017/03/recensie-pauline-vijverberg-suikervogeltje/",
  },
  {
    description: defaultDescription,
    image: require("../images/news/onlinebibliotheek-min.png"),
    url:
      "https://www.onlinebibliotheek.nl/ebooks/Pauline-Vijverberg/Onder-de-vlinderboom",
  },
  {
    description: defaultDescription,
    image: require("../images/news/leestafel-min.png"),
    url: "https://www.leestafel.info/pauline-vijverberg",
  },
  {
    description: "Alexine – Excentrieke reizigster fraai beschreven",
    image: require("../images/news/tpo-min.png"),
    url: "https://tpo.nl/2011/01/01/alexine-pauline-vijverberg/",
  },
  {
    description: "Alexine van Pauline Vijverberg",
    image: require("../images/news/engelfriet-min.png"),
    url: "http://www.engelfriet.net/Alie/Aad/alexine.htm",
  },
];

export default data;
