import React from "react";
import * as colors from "../core/Color";
import state from "../core/State";

const News = (data) => {
  const [, globalActions] = state();

  const sectionStyle = {
    backgroundColor: colors.gold,
  };

  const imgStyle = {
    height: "40vh",
  };

  const hrStyle = {
    paddingBottom: "0.2vh",
    border: "none",
    backgroundColor: colors.white,
  };

  const headerStyle = {
    color: colors.white,
  };

  const linkStyle = {
    color: colors.white,
  };

  return (
    <section id="News" style={sectionStyle}>
      <div style={{ height: "8vh" }} />
      <div className={"container"} align="center">
        <h1 style={headerStyle}>{globalActions.decide("News", "Actueel")}</h1>
        <hr style={hrStyle} />
        <div style={{ height: "4vh" }} />
        <div className={"row"}>
          <div className={"col-12"}>
            <div
              id="news-carousel"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner" align="center">
                {Array.from(data.news).map((article, i) => {
                  const active = i === 0 ? " active" : "";
                  return (
                    <div key={i} className={"carousel-item" + active}>
                      <div
                        align="center"
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <a href={article.url} target="_blank" style={linkStyle}>
                          {article.description}
                        </a>
                      </div>
                      <div style={{ height: "2vh" }} />
                      <img
                        src={article.image}
                        alt={"news-article-" + i}
                        style={imgStyle}
                      />
                    </div>
                  );
                })}
              </div>
              <a
                className="carousel-control-prev"
                href="#news-carousel"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                />
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#news-carousel"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                />
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "8vh" }} />
    </section>
  );
};

export default News;
