import React from "react";
import * as colors from "../core/Color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import state from "../core/State";

const Contact = () => {
  const [, globalActions] = state();

  const sectionStyle = {
    backgroundColor: colors.green,
    height: "50vh",
  };

  const hrStyle = {
    paddingBottom: "0.2vh",
    border: "none",
    backgroundColor: colors.white,
  };

  const headerStyle = {
    color: colors.white,
  };

  return (
    <section id="Contact" style={sectionStyle}>
      <div style={{ height: "8vh" }} />
      <div className={"container"} align="center">
        <h1 style={headerStyle}>Contact</h1>
        <hr style={hrStyle} />
        <div style={{ height: "4vh" }} />
        <div className={"row"}>
          <div className={"col-12"} align={"center"}>
            <h5 style={{ color: colors.white }}>
              {globalActions.decide(
                "Contact me on any of the following platforms",
                "Volg mij op de volgende platforms"
              )}
            </h5>
            <br />
            <a href="https://www.linkedin.com/in/pauline-vijverberg-a8458b3b/">
              <span style={{ color: colors.white, paddingRight: "30px" }}>
                <FontAwesomeIcon icon={faLinkedin} size="3x" />
              </span>
            </a>
            <a href="https://www.facebook.com/pauline.vijverberg">
              <span style={{ color: colors.white, paddingRight: "30px" }}>
                <FontAwesomeIcon icon={faFacebook} size="3x" />
              </span>
            </a>
            <a href="https://www.instagram.com/paulinevijverberg/?hl=en">
              <span style={{ color: colors.white, paddingRight: "30px" }}>
                <FontAwesomeIcon icon={faInstagram} size="3x" />
              </span>
            </a>
            <br />
            <br />
            <h5 style={{ color: colors.white }}>
              {globalActions.decide("Or at", "Of schrijf naar")}{" "}
              paulinevijverberg@gmail.com
            </h5>
          </div>
        </div>
      </div>
      <div style={{ height: "4vh" }} />
    </section>
  );
};

export default Contact;
