import React from "react";
import Background from "../images/main-min.jpg";
import * as colors from "../core/Color";
import state from "../core/State";

const Contact = () => {
  const [, globalActions] = state();

  const sectionStyle = {
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    height: "90vh",
  };

  const mainText = {
    fontFamily: "Pinyon Script",
    color: colors.gold,
    paddingTop: "30vh",
    fontSize: "5.5em",
  };

  return (
    <section id="Home" style={sectionStyle} align="center">
      <p style={mainText}>Pauline Vijverberg</p>
    </section>
  );
};

export default Contact;
