const alexine_english_description = `
This debut novel is based on the life of the Dutch adventurer Alexine Tinne. It tells the story
of a woman who chooses a path no other woman has taken before her, on camelback, with
wrought iron beds, boxes full of fine china and cases filled with books, more than a century
ago. She crosses deserts and searches for the places with no name, the white spots on the
map. She leaves conventions of the aristocratic Holland behind to travel into unknown
Africa.
She was the first female explorer in the Victorian era that the Netherlands could claim, noted
for her perseverance and intrepidity. Newspapers followed Livingstone, the Bakers, Speke
and Grant, all searching for the source of the Nile.
Alexine reached the southernmost navigable part of the Nile, deep into terra incognita and
became a celebrity instantly: every country wanted their own heroes and for Holland it was
Alexine.
`;

const alexine_dutch_description = `
Dit debuut is gebaseerd op het leven van de Nederlandse avonturierster Alexine Tinne. Het
vertelt het verhaal van een vrouw die voor een pad koos dat geen andere vrouw voor haar
had ingeslagen, op de rug van een kameel, met gietijzeren bedden, kisten vol porselein en
dozen gevuld met boeken, meer dan een eeuw geleden. Ze kruiste woestijnen en zocht naar
de plaatsten zonder naam, de witte vlekken op een kaart. Ze liet de conventies van het
aristocratische Holland achter zich om steeds verder het onbekende Afrika in te trekken. Ze
was de eerste vrouwelijke ontdekkingsreizigster in het Victoriaanse tijdperk waar Nederland
trots op kon zijn. Kranten volgden Livingstone, de Bakers, Speke en Grant, die allen zochten
naar de oorsprong van de Nijl. Alexine bereikte het zuidelijkste, meest bevaarbare deel van
de Nijl, diep terra incognita in en ze werd direct een beroemdheid: ieder land wilde zijn eigen
helden en voor Holland was dat Alexine.
`;

const het_suikervogeltje_english_description = `
Het Suikervogeltje is a historical novel based on the true story of orphan girls from the
seventeenth century, who emigrated to Cape of Good Hope.
The year is 1688. The destination is Cape of Good Hope. There is a shortage of Dutch
women. The orphan Ariaan, adventurous, dreamy and sincere, travels with her sister
Willemijn and six others to an unknown continent. The invitation to become brides is from
the Dutch East India Company.
This novel is about the bond between two sisters. It is about hope and the taste for
adventure. It is about guilt and betrayal, but most of all it is about loyalty and love.
The history of South-Africa in the period between 1687-1707, during the administration of
father and son Van der Stel, forms the background of the story.
`;

const het_suikervogeltje_dutch_description = `
Het Suikervogeltje is een historische roman gebaseerd op het leven van weesmeisjes in de
zeventiende eeuw die naar Zuid-Afrika emigreren.
Het jaar is 1688. De bestemming is Kaap de Goede Hoop. Er is een tekort aan Hollandse
vrouwen. Het weesmeisje Ariaan, avontuurlijk, dromerig en oprecht, vaart met haar zusje
Willemijn en zes anderen op uitnodiging van de VOC naar een onbekend continent als een
‘bruid op bestelling’.
Deze roman gaat over de band tussen twee zusjes. Het gaat over hoop en de hang naar
avontuur, over schuldgevoel en verraad, maar bovenal gaat het over loyaliteit en liefde. De
geschiedenis van Zuid-Afrika in de periode 1687-1707, tijdens het bestuur van vader en zoon
Van der Stel (1687-1707),  vormt de achtergrond van het verhaal.
`;

const onder_de_vlinderboom_english_description = `
The twins Clara and Tony speak to each other in unfinished sentences. That is how they
share their secrets, dreams and happiness. They can read each other’s minds without a need
for words. Their lives radically turn for the worste when in 1900 during the Anglo-Boer war
their farm is set alight and they end up in a concentration camp close to Pretoria.
One hundered years later researcher Iris is intrigued by epigenetics: how patterns form the
past are repeating in the present. Iris discovers a family secret and finds that her research in
Holland echoes across the continents in South Africa. She unearths a story of inconceivable
hardship and unimaginable resilience that will change her life forever.
`;

const onder_de_vlinderboom_dutch_description = `
De tweeling Clara en Tony spreekt met elkaar in onafgemaakte zinnen. Zo delen ze hun
geheimen, dromen en blijdschap. Ze kunnen elkaars gedachten lezen zonder woorden te
hoeven gebruiken. Hun leven neemt op slag een drastische wending wanneer hun boerderij
in 1900 tijdens de Boerenoorlog wordt afgebrand en ze met hun moeder in een kamp vlakbij
Pretoria terechtkomen.
Honderd jaar later is de wetenschapper Iris geïntrigeerd door epigenetica: hoe patronen uit
het verleden zich in het heden herhalen. Iris stuit op een familiegeheim en ontdekt dat haar
onderzoek in Holland over de continenten heen tot in Zuid-Afrika echoot. Ze ontrafelt een
verhaal van ondenkbare ontberingen en onvoorstelbare veerkracht dat haar leven voorgoed
zal veranderen.
`;

const de_achtste_zonnebloemen_dutch_description = `
Bijna niemand weet dat Van Goghs jongste broer naar Zuid-Afrika verhuisde. Of dat het nichtje van de muze van Breitner wilde leven zoals muziek klinkt. 

Duikt in Zuid-Afrika een nieuw zonnebloemen-schilderij op? Vincent van Gogh schilderde de zonnebloemenserie in het jaar dat zijn jongste broer Cor naar Zuid-Afrika vertrok. Zeven versies, om het Gele Huis op te vrolijken. Gaf hij een achtste mee aan zijn broer? 

Cor raakt bevriend met de entomoloog Jacobus Swierstra en de zusjes Niesje en Geesje Kwak, model voor de kimono schilderijen van Breitner. Zij zijn net als Cor, Jacobus en vele andere Nederlanders, naar de Transvaal geëmigreerd met hoop op een betere toekomst.

Aafke is jaren later terug in Zuid-Afrika om te zorgen voor haar zieke vader die niet meer spreken kan. Ze is ook op zoek naar het schilderij dat vroeger bij haar thuis hing: de vaas met zonnebloemen. Vindt zij het schilderij dat haar enige herinnering is aan haar overleden moeder?

In deze roman cirkelen feit en fictie om elkaar heen. Het is een geschiedenis van triomf en tragedie en alles wat daartussen ligt. Het leest als een liefdesbrief over vriendschap en verlies, over de kracht van herinneringen, over de kunst om gelukkig te leven. En over liefde in al zijn vormen.
`;

export {
  alexine_english_description,
  alexine_dutch_description,
  het_suikervogeltje_english_description,
  het_suikervogeltje_dutch_description,
  onder_de_vlinderboom_english_description,
  onder_de_vlinderboom_dutch_description,
  de_achtste_zonnebloemen_dutch_description,
};
