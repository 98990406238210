import React from "react";
import useGlobalHook from "use-global-hook";

const ENGLISH = "English";
const DUTCH = "Dutch";

const initialState = {
  lang: DUTCH,
};

const actions = {
  switch: (store) => {
    const newLang = store.state.lang === ENGLISH ? DUTCH : ENGLISH;
    store.setState({ lang: newLang });
  },
  getOpposite: (store) => {
    return store.state.lang === ENGLISH ? DUTCH : ENGLISH;
  },
  decide: (store, english, dutch) => {
    return store.state.lang === ENGLISH ? english : dutch;
  },
};

const State = useGlobalHook(React, initialState, actions);

export default State;
