import React from "react";
import * as colors from "../core/Color";
import state from "../core/State";
import video from '../videos/intro.mp4'

const Book = () => {
    const [, globalActions] = state();

    const sectionStyle = {
        backgroundColor: colors.white,
    };

    const hrStyle = {
        paddingBottom: "0.2vh",
        border: "none",
        backgroundColor: colors.grey,
    };

    const headerStyle = {
        color: colors.grey,
    };

    return (
        <section id="Videos" style={sectionStyle}>
            <div style={{height: "8vh"}}/>
            <div className={"container"} align="center">
                <h1 style={headerStyle}>{globalActions.decide("Videos", "Video’s")}</h1>
                <hr style={hrStyle}/>
                <div style={{height: "4vh"}}/>
                <div className={"row"} align="center">
                    <div className={"col-md-12 col-lg-12"}>
                        <video width="848" height="480" controls>
                            <source src={video} type="video/mp4" />
                        </video>

                        <div className={"d-lg-none"}>
                            <div style={{height: "2vh"}}/>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12 col-lg-6"}>
                        <iframe
                            title="pauline"
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/suXJ6mCF0wY"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />

                        <div className={"d-lg-none"}>
                            <div style={{height: "2vh"}}/>
                        </div>
                    </div>
                    <div className={"col-md-12 col-lg-6"}>
                        <iframe
                            title="conserve"
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/TIQt2yb4Ca8"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                </div>
            </div>

            <div style={{height: "8vh"}}/>
        </section>
);
};

export default Book;
